import { React, useState } from 'react';
import { useStore } from '../../../Contexts/StoreContext';
import Swal from 'sweetalert2';
import axios from '../../../Utils/axios';
import { useNavigate, Link } from 'react-router-dom';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const VerifyAccount = () => {
  const { setIsLoading } = useStore();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [data, setData] = useState({
    email: '',
    phone_no: '',
    country_code: '',
    otp: 0,
  });
  const [emailLogin, setEmailLogin] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (emailLogin && data.email === '')
      return Toast.fire({ icon: 'error', title: 'Email required' });
    if (!emailLogin && data.phone_no === '')
      return Toast.fire({ icon: 'error', title: 'Phone No required' });

    setIsLoading(true);

    if (page === 1) {
      generateOTP();
    } else {
      verifyOTP();
    }
  };

  const generateOTP = async () => {
    try {
      const params = {};

      if (emailLogin) {
        params.email = data.email;
      } else {
        params.phone_no = Number(data.phone_no);
        params.country_code = Number(data.country_code);
      }

      const res = await axios().post(
        '/api/v1/auth/account-verification/generate-otp',
        params
      );

      console.log(params, res.data);

      Toast.fire({
        icon: 'success',
        title: res.data.msg,
      });
      setIsLoading(false);
      setPage(2);
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: 'error',
        title: err.response.data ? err.response.data.msg : err.message,
      });
      setIsLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      const params = {
        otp: Number(data.otp),
      };

      if (emailLogin) {
        params.email = data.email;
      } else {
        params.phone_no = Number(data.phone_no);
        params.country_code = Number(data.country_code);
      }

      const res = await axios().post(
        '/api/v1/auth/account-verification/activate-account',
        params
      );

      console.log(res);

      Toast.fire({
        icon: 'success',
        title: res.data.msg,
      });
      setIsLoading(false);
      navigate('/sign-in');
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: 'error',
        title: err.response.data ? err.response.data.msg : err.message,
      });

      if (err.response.data.msg === 'Email is already verified') {
        navigate('/sign-in');
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <form className="form-horizontal">
        <h2 style={{ color: '#775DA8' }}>Verify Account</h2>
        <h6>Please enter details</h6>
        <h6>Enter your mobile number or Email to continue.</h6>
        <span>Continue with : </span>
        <div className="form-check form-switch d-inline-block mt-5 mb-3">
          <span
            className="form-check-span pl-0"
            htmlFor="emailPhoneSwitch"
            style={{
              color: !emailLogin && 'grey',
              fontWeight: emailLogin && 'bold',
            }}
          >
            Email
          </span>
          <input
            className="form-check-input ml-2 d-inline-block"
            type="checkbox"
            role="switch"
            id="emailPhoneSwitch"
            onChange={() => setEmailLogin((prev) => !prev)}
            disabled={page === 2}
          />
          <span
            className="form-check-span ml-5"
            htmlFor="emailPhoneSwitch"
            style={{
              color: emailLogin && 'grey',
              fontWeight: !emailLogin && 'bold',
            }}
          >
            Phone No
          </span>
        </div>

        <div className="form-group row">
          {emailLogin ? (
            <>
              <label htmlFor="emailField" className="form-label">
                Email
              </label>
              <div className="">
                <input
                  type="email"
                  className="form-control"
                  id="emailField"
                  placeholder="abc@email.com"
                  value={data.email}
                  disabled={page === 2}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </div>
            </>
          ) : (
            <>
              <label htmlFor="phoneNoField" className="form-label">
                Phone No
              </label>
              <div className="d-flex">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    +
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="countryCodeField"
                    style={{ maxWidth: '50px' }}
                    disabled={page === 2}
                    placeholder="XX"
                    value={data.country_code}
                    onChange={(e) =>
                      setData({
                        ...data,
                        country_code: e.target.value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="phoneNoField"
                    disabled={page === 2}
                    placeholder="XXXXXXXXXX"
                    value={data.phone_no}
                    onChange={(e) =>
                      setData({ ...data, phone_no: e.target.value })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {page !== 1 && (
          <>
            <div className="form-group row">
              <label htmlFor="otp" className="form-label">
                Enter your OTP
              </label>
              <div className="input-group mb-1">
                <input
                  name="otp"
                  className="form-control  border-right-0"
                  id="otp"
                  type="number"
                  placeholder="otp"
                  value={data.otp}
                  onChange={(e) =>
                    setData({ ...data, otp: Number(e.target.value) })
                  }
                />
                <span
                  className="input-group-text btn btn-success "
                  onClick={() => generateOTP()}
                >
                  Get OTP again
                </span>
              </div>
            </div>
          </>
        )}

        <button
          type="submit"
          className="btn btn-success w-100"
          onClick={(e) => handleSubmit(e)}
        >
          {page === 1 && 'Get OTP'}
          {page === 2 && 'Verify Account'}
        </button>
        <div className="my-2 text-center ">
          <Link to="/sign-in">Back</Link>
        </div>
      </form>
    </>
  );
};

export default VerifyAccount;
