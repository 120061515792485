import React from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { useStore } from '../Contexts/StoreContext';

import Header from '../Layout/Header';
import Menu from '../Layout//Menu';
import Footer from '../Layout//Footer';
import AuthLayout from '../Layout/AuthLayout';

import SignIn from '../Pages/Auth/SignIn/SignIn';
import SignUp from '../Pages/Auth/SignUp/SignUp';
import ForgetPassword from '../Pages/Auth/ForgetPassword/ForgetPassword';
import VerifyAccount from '../Pages/Auth/VerifyAccount/VerifyAccount';

import Dashboard from '../Pages/Dashboard/Dashboard';
import AppBanner from '../Pages/App/Banner';
import AppEvent from '../Pages/App/Event';
import AppFeedback from '../Pages/App/Feedback';
import AppWelcomeScreen from '../Pages/App/WelcomeScreen';
import AppNotification from '../Pages/App/Notification';
import AppAboutUs from '../Pages/App/AboutUs';
import AppContactUs from '../Pages/App/ContactUs';
import AppFAQ from '../Pages/App/FAQ';
import AppHelp from '../Pages/App/Help';
import AppPrivacyPolicy from '../Pages/App/PrivacyPolicy';
import AppTermsAndCondition from '../Pages/App/TermsAndCondition';

import Profile from '../Pages/Profile/Profile';
import Customers from '../Pages/Customers/Customers';
import CustomerProfile from '../Pages/Customers/CustomerProfile';
import CourseCategory from '../Pages/CourseCategory/CourseCategory';
import Course from '../Pages/Course/Course';
import CourseSeries from '../Pages/Course/CourseSeries';
import CourseSeriesPlan from '../Pages/Course/CourseSeriesPlan';
import Quiz from '../Pages/Quiz/Quiz';
import QuizSection from '../Pages/Quiz/QuizSection';
import QuizSectionQuestion from '../Pages/Quiz/QuizSectionQuestion';

export default function Routing() {
  const { isLoading, setIsLoading } = useStore();

  return (
    <div
      className="wrapper"
      style={{ overflowX: 'hidden', position: 'relative' }}
    >
      <div
        style={{
          display: isLoading ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          zIndex: 1060,
          height: '100vh',
          width: '100vw',
          placeItems: 'center',
          background: 'rgba(0,0,0,0.4)',
        }}
      >
        <div className="d-flex flex-column align-items-center gap-5">
          <div className="spinner-border" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <button className="btn btn-dark" onClick={() => setIsLoading(false)}>
            Close
          </button>
        </div>
      </div>
      {/* <div
        style={{
          display: isLoading ? 'flex' : 'none',
          position: 'fixed',
          zIndex: 1000,
          height: '100vh',
          width: '100vw',
          justifyContent: 'center',
          alignItems: 'end',
          padding: '20px',
        }}
      >
        <button className="btn btn-dark" onClick={() => setIsLoading(false)}>
          Close
        </button>
      </div> */}
      <Routes>
        {/* Unprotected Routes */}
        <Route element={<UnprotectedRoute />}>
          <Route element={<AuthLayout />}>
            <Route path="/sign-in" element={<SignIn />} />
            <Route exact path="/sign-up" element={<SignUp />} />
            <Route exact path="/verify-account" element={<VerifyAccount />} />
            <Route exact path="/forget-password" element={<ForgetPassword />} />
          </Route>
        </Route>

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />

          <Route path="/app">
            <Route path="banner" element={<AppBanner />} />
            <Route path="event" element={<AppEvent />} />
            <Route path="feedback" element={<AppFeedback />} />
            <Route path="welcome-screen" element={<AppWelcomeScreen />} />
            <Route path="notification" element={<AppNotification />} />
            <Route path="about-us" element={<AppAboutUs />} />
            <Route path="contact-us" element={<AppContactUs />} />
            <Route path="faq" element={<AppFAQ />} />
            <Route path="help" element={<AppHelp />} />
            <Route path="privacy-policy" element={<AppPrivacyPolicy />} />
            <Route
              path="terms-and-condition"
              element={<AppTermsAndCondition />}
            />
          </Route>
          <Route path="/profile" element={<Profile />} />

          {/* Customers */}
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:userId" element={<CustomerProfile />} />

          {/* Course */}
          <Route path="/course-categories" element={<CourseCategory />} />
          {/* will manage all course categories */}
          <Route path="/courses" element={<Course />} />
          {/* will manage all courses (can be filtered by course-categories) and option to manage course series and manage quiz */}
          <Route path="/courses/:courseId" element={<CourseSeries />} />
          {/* will manage all course series in particular course */}
          <Route
            path="/courses/:courseId/:courseSeriesId"
            element={<CourseSeriesPlan />}
          />
          {/* will manage all course series plan in particular course series */}

          {/* Quiz */}
          <Route path="/quiz/:courseId/:courseSeriesId" element={<Quiz />} />
          {/* will manage all quiz inside one course  */}
          <Route
            path="/quiz/:courseId/:courseSeriesId/:quizId"
            element={<QuizSection />}
          />
          {/* will manage all sections inside one quiz  */}
          <Route
            path="/quiz/:courseId/:courseSeriesId/:quizId/:sectionId"
            element={<QuizSectionQuestion />}
          />
          {/* will manage all questions inside one quiz section */}
        </Route>
        <Route path="/*" element={<div>Not Found</div>} />
      </Routes>
    </div>
  );
}

const ProtectedRoute = () => {
  const access_token = localStorage.getItem('access_token');

  if (access_token) {
    return (
      <>
        <Header />
        <Menu />
        <Outlet />
        <Footer />
      </>
    );
  } else {
    return <Navigate to="/sign-in" />;
  }
};

const UnprotectedRoute = () => {
  const access_token = localStorage.getItem('access_token');

  if (access_token) {
    return (
      <>
        <Navigate to="/" />
      </>
    );
  } else {
    return <Outlet />;
  }
};
